.right-nav {
    display: flex;
    align-items: center;
    gap: 3rem;
    z-index: 4;
}

.nav-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #FFF;
    font-family: Figtree;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.register {
    width: 60%;
    height: auto;
    padding: 1rem;
    font-size: 20px;
    display: flex;
    gap: 3rem;
    align-items: center;
    margin: 2rem auto;
    background-color: #fff;
    position: relative;
    border-radius: 4px;

}

.forms-content {
    width: 70%;
}

.register img {
    width: 40%;
    border-radius: 12px;
    max-height: 80vh;
}

.forms-heading {
    color: #D70F0F;
    font-family: Figtree;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
}

.forms-subheading {
    color: #000;
    font-family: Figtree;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2rem;
}

.close-btn {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
}

.close-btn img {
    width: 1rem;
}

.forgot-password-link {
    color: #005424;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.error-message {
    color: red;
    font-size: 15px;
    font-family: Figtree;
    height: 10px;
    margin-bottom: 1rem;
    /* border: 1px solid black; */
}

@media (max-width: 900px) {
    .register > img {
        display: none;
    }
    .forms-content {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .forms-heading {
        font-size: 1.5rem;
    }
    .forms-subheading {
        font-size: 15px;
        margin-bottom: 1rem;
    }
}