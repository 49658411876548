.kid-card {
    width: 10rem;
    height: auto;
    flex-shrink: 0;
    border-radius: 20px;
    border: 2px solid #EAEAEA;
    cursor: pointer;
    padding: 2rem 3rem;
    display: flex;
    gap: 1rem;
    flex-flow: column;
}

.kid-card.active, .kid-card:hover {
    border: 2px solid #D70F0F;
    background: rgba(215, 15, 15, 0.04);
}

.kid-card img {
    width: auto;
    flex-shrink: 0;
}

.kid-card p {
    color: #000;
    text-align: center;
    font-family: Figtree;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.kid-card.active p {
    color: #D70F0F;
    margin: 0;
    margin-top: 1rem;
}

.kids-heading {
    color: #D70F0F;
    font-family: Figtree;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    margin-bottom: 1rem;
}