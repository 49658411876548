.parent-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  color: #292929;
  text-align: center;
  font-family: Figtree;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.parent-success img:nth-of-type(1) {
  width: 2.5rem;
}

.parent-success img:nth-of-type(2) {
    width: 24rem;
    margin-top: 2rem;
  }

.parent-success p {
  margin: 0;
  margin-top: 1rem;
}

.link-container {
  font-size: 1rem;
}

.link-section {
  display: flex;
  align-items: center;
  padding: 0 16px;
  max-width: 90%;
  flex-direction: column;
  gap: 10px;
}

.link-section a {
  color: blue;
  display: inline-block;
  margin-right: 8px;
  word-break: break-all;
}

.copy-to-clipboard-container {
    height: 50px;
}
