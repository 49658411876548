html,
body {
    margin: 0;
    padding: 0;
}

.hero {
    min-height: 100vh;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero .hero-text {
    position: absolute;
    height: 100vh;
    width: 95%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    color: #FFF;
    font-family: Figtree;
    font-style: normal;
    line-height: normal;
}

.hero .hero-text>* {
    text-align: left;
}

.hero .small {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0.5rem 0;
}

.hero .big {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0.5rem 0;
}

.hero .normal {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0.5rem 0;
}

.hero-container {
  position: relative;
  overflow: hidden;
  /* min-height: calc(95vh - 30px); */
  height: 100vh;
  width: 100%;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* @media (max-width: 768px) {
  .video-background {
    width: 100vw;
    height: auto;
  }
} */

.hero-container .hero-text {
  position: absolute;
  bottom: 70px; /* Adjust for desired positioning */
  right: 100px;
  /* max-width: 500px; Set a max width if needed */
}

.hero-container .hero-text-content {
  background-color: #ac0101; /* Dark red with transparency */
  color: white;
  padding: 20px;
  border-radius: 8px;
  /* border: 1px solid black; */
  width: 38rem;
}

.hero-container .small {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0.5rem 0;
}

.hero-container .big {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0.5rem 0;
}

.hero-container .normal {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0.5rem 0;
}

.content-1, .content-2, .content-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4rem;
    border-bottom: 1px solid #D9D9D9;
  }
  
  .content-1-text, .content-3-text {
    width: 60%;
  }

  .content-2-text {
    width: 100%;
  }
  
  .content-1 img {
    width: 30%;
    height: auto;
  }

  .content-3 img {
    width: 30%;
    height: auto;
  }
  
  .content-2-cards {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .content-2-cards > img {
    height: 2rem;
  }
  
  .content-2-cards .cards-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: left;
    gap: 2rem;
  }
  
  .content-2-cards .cards-item img {
    height: 8rem;
    width: auto;
  }

  .content-1-text img {
    display: none;
  }

  .content-3-text img {
    display: none;
  }

  .hero {
    display: none;
  }

  .hero-container {
    display: block;
  }

  .content-3 {
    position: relative;
  }

  .content-3 .star-1 {
    position: absolute;
    bottom: 50%;
    left: 15%;
    transform: translateX(-50%);
  }

  .content-3 .star-2 {
    display: none;
  }
  
  /* Media Queries for Responsiveness */
  
  /* For Tablet and Smaller Screens */
  @media (max-width: 768px) {
    .content-1, .content-2, .content-3 {
      flex-direction: column;
      text-align: center;
    }
  
    .content-1-text, .content-2-text, .content-3-text {
      width: 100%;
      padding: 0 1rem;
    }
  
    .content-1 img {
      width: 80%;
      margin: 2rem auto 0;
      display: none;
    }

    .content-1-text img {
      display: block;
      width: 60%;
      margin-bottom: 1rem;
    }

    .content-3 img {
      width: 60%;
      margin: 2rem auto 0;
      display: none;
    }

    .content-3-text img {
      display: block;
      width: 40%;
      margin-bottom: 1rem;
    }
  
    .content-2-cards {
      flex-direction: column;
      gap: 1.5rem;
    }
  
    .content-2-cards > img {
      height: 1.5rem;
    }
  
    .content-2-cards .cards-item img {
      height: 6rem;
    }

    .content-3 .star-1 {
      display: none;
    }

    .content-3 .star-2 {
      display: block;
      position: absolute;
      bottom: 45%;
      left: 48%;
      transform: translateX(-50%);
    }
  }
  
  /* For Mobile Screens */
  @media (max-width: 480px) {
    .content-1, .content-2, .content-3 {
      padding-bottom: 2rem;
    }
  
    .big-text {
      font-size: 2rem;
    }
  
    .medium-text {
      font-size: 1.25rem;
    }
  
    .small-text {
      font-size: 0.875rem;
    }
  
    .content-1 img {
      width: 100%;
      margin: 1rem auto 0;
      display: none;
    }

    .content-3 img {
      width: 70%;
      margin: 1rem auto 0;
      display: none;
    }

    .content-3-text img {
      display: block;
      margin-bottom: 1rem;
    }
  
    .content-2-cards {
      gap: 1rem;
    }
  
    .content-2-cards .cards-item img {
      height: 5rem;
    }
  
    .content-2-cards > img {
      height: 1.25rem;
    }

    .content-1-text img {
      display: block;
      margin-bottom: 1rem;
    }

    .hero {
      display: block;
    }

    .hero-container {
      display: none;
    }

  }
  
