.giftgiver {
    padding: 2rem;
}

.giftgiver .heading {
    color: #AC0101; /* Updated color */
    font-family: Figtree;
    font-size: 2.5rem; /* Increased font size */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
    text-align: left; /* Align text to the left */
}

.giftgiver .subtext {
    color: #292929;
    font-family: Figtree;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem; /* Add space below the subtext */
    text-align: left; /* Align text to the left */
}


.giftgiver .content {
    width: 100%;
    margin: 1rem 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 200px; /* Ensure content fits */
    box-sizing: border-box;
}

.registry-list {
    margin-bottom: 200px;
}

.giftgiver .cta {
    display: flex;
    justify-content: flex-end; /* Align button to the right */
    margin-top: 2rem;
}

.registry-list .product-list {
    margin: 1.5rem 0;
    padding: 0 1rem;
}

.registry-list .product-list-item {
    margin: 1rem 0;
}

.registry-list .item-heading {
    font-size: 1.2rem;
    font-weight: 900;
}

.registry-list .item-products {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
}

.registrylist-footer {
    background-color: #e5ffe5;
    color: black;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 0px;
    width: 100%;
}


/* Responsive styles */
@media (max-width: 768px) {
    .giftgiver {
        padding: 1rem;
    }

    .giftgiver .heading {
        font-size: 1.25rem;
    }

    .giftgiver .subtext {
        font-size: 0.75rem;
    }

    .giftgiver .content {
        margin: 1rem 0;
        min-height: 150px;
    }

    .giftgiver .cta {
        flex-direction: column;
        align-items: center;
    }

    /* .registry-list .cta {
        flex-direction: column;
        align-items: center;
    } */

    .registry-list .item-products {
        flex-wrap: wrap;
        gap: 8px;
    }
}

@media (max-width: 480px) {
    .giftgiver .heading {
        font-size: 1rem;
    }

    .giftgiver .subtext {
        font-size: 0.7rem;
    }

    .giftgiver .content {
        min-height: 120px;
    }

    .registry-list .item-products {
        gap: 4px;
    }

    .registrylist-footer {
        flex-direction: column;
        gap: 20px;
    }
}
