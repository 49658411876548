.profile {
  /* padding: 2rem 2.5rem; */

  .MuiFormControl-root {
    width: 60%;
  }

  @media (max-width: 480px) {
    .MuiFormControl-root {
      width: 90%;
    }
  }
}

.profile-view {
  padding: 4rem 3.5rem;
  .MuiFormControl-root {
    width: 60%;
  }
}

.label {
  font-size: 20px;
  font-weight: 400;
}

.value {
  font-family: Figtree;
  font-size: 18px;
}

/* .step1-video {
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 100000;
}

.step1-video video {
  border-radius: 50%;
  width: 15vh;
  height: 15vh;
  z-index: 100000;
} */