Positioning each star around its content
.svg-one, .svg-two, .svg-three {
  display: inline-block;
  overflow: visible;
  position: relative;
  transform-origin: center;
}

svg .group {
  transform: translate(50%, 50%);
}

.group path {
  transform: translate(-50%, -50%);
}

.svg-one .large,
.svg-one .large-2,
.svg-one .small {
  animation: sparkle-one-large 2.5s infinite 0s;
}

.svg-two .large,
.svg-two .large-2,
.svg-two .small {
  animation: sparkle-two-large 2.5s infinite 0.8s;
}

.svg-three .large,
.svg-three .large-2,
.svg-three .small {
  animation: sparkle-three-large 2.5s infinite 1.6s;
}

/* Keyframes for each sparkle animation */
@keyframes sparkle-one-large {
  0% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }
  50% {
    opacity: 1;
    transform: rotate(180deg) scale(1.8);
  }
  100% {
    opacity: 0;
    transform: rotate(360deg) scale(0);
  }
}

@keyframes sparkle-two-large {
  0% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }
  50% {
    opacity: 1;
    transform: rotate(180deg) scale(1.8);
  }
  100% {
    opacity: 0;
    transform: rotate(360deg) scale(0);
  }
}

@keyframes sparkle-three-large {
  0% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }
  50% {
    opacity: 1;
    transform: rotate(180deg) scale(1.8);
  }
  100% {
    opacity: 0;
    transform: rotate(360deg) scale(0);
  }
}
