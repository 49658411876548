@keyframes fall {
    0% {
      transform: translateY(-100vh); /* Start above the viewport */
      opacity: 0;                    /* Start invisible */
    }
    10% {
      opacity: 1;                    /* Fade in quickly */
    }
    100% {
      transform: translateY(100vh);  /* End below the viewport */
      opacity: 1;                    /* Fully visible */
    }
  }
  
  .Snowflake {
    animation: fall 10s linear infinite;
    font-family: Figtree;
    color: white;
    position: absolute;
    pointer-events: none;
    user-select: none;
  }
  