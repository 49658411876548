
.kids-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    color: #292929;
    text-align: center;
    font-family: Figtree;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.kids-success img {
    width: 2.5rem;
}

.kids-success p {
    margin: 0;
    margin-top: 1rem;
}