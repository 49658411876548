
.settings-conatiner {
    /* margin: 40px; */
}

.settings-label {
    font-size: 30px;
    color: red;
    font-weight: bold;
    margin-bottom: 30px;
}

/* style={{ display: 'flex', flexDirection: 'column', gap: '30px' }} */

.deactivate-conatiner {
    display: flex;
    flex-direction: column;
    gap: 30px;
}