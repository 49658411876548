.product-card {
  display: flex;
  width: 45%;
  gap: 1rem;
  padding: 10px;
}

@media (max-width: 1200px) {
  .product-card {
    display: flex;
    width: 100%;
    gap: 1rem;
  }
}

.product-card .product-img {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 45%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-size: 80%;
}

@media (max-width: 480px) {
  .product-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .product-card .product-img {
    width: 100%;
    height: 100px;
  }
}

.product-card .product-img img {
  width: 100%;
}

.product-card .details .description {
  font-size: 13px;
}

.product-card .details {
  width: 100%;
  /* border: 1px solid black; */
}

/* .description {
    height: 4rem;
    overflow: hidden;
} */

.product-card .details .details-price {
  display: flex;
  align-items: center;
}

.product-card .details .details-price .price {
  padding-right: 8px;
  color: #000;
  font-weight: 700;
  font-family: Figtree;
  font-size: 1rem;
  margin: 0.75rem 0;
}

.product-card .details .details-price .original-price {
  padding-right: 8px;
  border-right: 2px solid #d9d9d9;
  color: #6a6060;
  font-weight: 700;
  font-family: Figtree;
  font-size: 1rem;
  margin: 0.75rem 0;
  text-decoration: line-through;
}

.product-card .details .details-price .platform {
  margin-left: 8px;
}

.product-card .details .details-price .platform img {
  height: 1rem;
}

.selected-product {
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 15px;
  border: 2px solid #005424;
  background: #005424;
  box-shadow: 0px 3px 14px 0px rgba(17, 0, 0, 0.2);
  color: #fff;
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  width: 4.5rem;
}

/* .selected-product img {
    width: 0px;
} */

.unslected-product {
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 15px;
  border: 1px solid #005424;
  box-shadow: 0px 3px 14px 0px rgba(17, 0, 0, 0.2);
  cursor: pointer;
  color: #005424;
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 4.5rem;
}

.acknowledge-purchase {
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 15px;
  box-shadow: 0px 3px 14px 0px rgba(17, 0, 0, 0.2);
  cursor: pointer;
  color: #005424;
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: white;
  border: 1px solid #F16C6C;
  background-color: #F16C6C;
}

.acknowledged-purchase {
  background-color: #005424;
  border: 1px solid #005424;
}

.acknowledge-purchase:hover {
  background-color: #005424;
  color: white;
}

.select-view-buttons {
  display: flex;
  gap: 5px;
}

.view-product-details {
  position: relative;
}

.view-product-details .description {
  font-size: 1rem;
  text-align: center;
  padding: 25px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

@media (min-width: 600px) {
  .content-container {
    flex-direction: row;
    justify-content: center;
  }
}

.product-display img {
  width: 100%;
  max-width: 150px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.product-features {
  text-align: center;
}

.product-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.product-stars {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.rating {
  font-size: 1.3rem;
}

.product-price {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.product-platform img {
  width: 80px;
  margin: 0.5rem 0;
}

.unselected-product-view-details,
.selected-product-view-details {
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  border: 2px solid #005424;
  box-shadow: 0px 3px 14px 0px rgba(17, 0, 0, 0.2);
  cursor: pointer;
  color: #005424;
  font-family: Figtree;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 8rem;
  border: 1px solid black;
}

.selected-product-view-details {
  background: #005424;
  color: #fff;
}

.selected-product-view-details img {
  width: 30px;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.product-card .all-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.product-card .all-buttons > * {
  margin-top: 8px;
}
