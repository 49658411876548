.product-carousel .title {
    color: #292929;
    font-family: Figtree;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0rem;
}

.product-carousel .title span {
    color: #D70F0F;
}

.product-carousel .carousel {
    width: calc(100% - 60px);
    margin: auto;
}

.product-carousel {
    margin-bottom: 24px;
}