.cart span {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #266A3F;
    color: #fff;
    font-size: 0.5rem;
    position: absolute;
    right: -6px;
    top: -6px;
    border-radius: 50%;
}

.cart {
    position: relative;
    width: auto;
}

.cart img {
    width: 20px;
}