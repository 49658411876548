.product-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1276px) {
  .product-list-header {
    flex-direction: column;
    align-items:flex-start;
    margin-bottom: 2rem;
  }
}


/* .label-input {
  margin-left: 1rem;
}

.label-input input {
  color: #292929;
  font-size: 1rem;
  font-weight: 700;
} */


/* .letter-modal {
    position: absolute;
    top: 10%;
    left: 30%;
    background-image: url('./images/horizontal1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 60%;
    height: 80%;
  } */

  /* .letter-modal {
    position: absolute;
    top: 10%;
    left: 20%;
    background-image: url('../../../../../../images/horizontal1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 1200px;
    height: 650px;
  } */