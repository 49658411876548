.select-path-img {
    width: 500px;
    height: 500px;
    /* border-radius: 50%; */
    object-fit: contain;
}

/* Outer container */
.select-path-container-outer {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    height: 90vh;
    padding: 20px;
    gap: 9vw;
}

/* Container holding images and buttons */
.select-path-container {
    display: flex;
    gap: 10vw;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap; /* Wrap items for smaller screens */
}

/* Image and button layout */
.img-button {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

/* Button styling */
.action-button {
    width: 200px;
    padding: 10px;
    text-align: center;
    background-color: red;
    border-radius: 8px;
    color: white;
    font-size: 18px;
    font-weight: bold;
}
.action-button:hover {
    cursor: pointer;
}

/* Previous button container */
.select-path-buttons {
    align-self: center;
    /* margin-top: 50px; */
    /* padding: 20px; */
}

@media (max-width: 1500px) {
    .select-path-img {
        width: 350px;
        height: 350px;
    }
    /* .action-button {
        width: 150px;
        font-size: 16px;
    } */
    .select-path-container {
        gap: 5vw;
    }
}

/* Responsive adjustments */
@media (max-width: 525px) {
    .select-path-img {
        width: 200px;
        height: 200px;
    }
    .action-button {
        width: 150px;
        font-size: 16px;
    }
    .select-path-container {
        gap: 5vw;
    }
}
