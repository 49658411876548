.btn {
    display: inline-flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    background: #005424;
    box-shadow: 0px 3px 14px 0px rgba(17, 0, 0, 0.20);
    color: #fff;
    cursor: pointer;
    color: #FFF;
    font-family: Figtree;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
}

.btn:disabled {
    cursor: not-allowed;
    background-color: #5b6760;
}

.btn.secondary {
    background: transparent;
    color: #000;
    border: 2px solid #005424;
    box-shadow: 0px 3px 14px 0px rgba(17, 0, 0, 0.20);
    padding: 8px 20px;
    font-size: 14px;
}

.btn.full-width {
    width: 100%;
}

.logo-btn {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: #FFF;
    font-family: Figtree;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.logo-btn img {
    height: 1.5rem;
}

