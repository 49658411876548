.footer {
    position: relative;
}

.footer img {
    width: 100%;
    height: 35vh;
    padding: 0;
    margin: 0;
    margin-bottom: -2rem;
    z-index: -1;
    margin-top: 40px;
}

.footer .footer-text {
    color: #fff;
    font-size: 1rem;
    bottom: 0.5vw;
    position: absolute;
    text-align: center;
    margin: auto;
    width: 100%;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

.footer span {
    color: #FB8989;
    margin: 0 4px;
}

/* Positioning each star */

.footer .star-two {
    position: absolute;
    bottom: 5%;
    left: 3%;
    transform: translateX(-50%);
}


.footer .star-three {
    position: absolute;
    bottom: -5%;
    left: 35%;
    transform: translateX(-50%);
}

.footer .star-one {
    position: absolute;
    bottom: 10%;
    left: 98%;
    transform: translateX(-100%);
}

.break-footer {
    display: none;
}

@media (max-width: 480px) {
    .break-footer {
        display: block;
    }
    /* Adjust star positions for mobile if needed */
    .footer .star-one {
        top: 75%;
        left: 15%;
    }
    .footer .star-two {
        display: none;
    }
    .footer .star-three {
        bottom: 20px;
        left: 90%;
        transform: translateX(-50%);
    }
}
