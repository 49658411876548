
.letter-page-modal {
    color: black;
    width: 100%;
    height: 100%;
}

.letter-page {
    color: black;
    overflow: auto;
    height: 100%;
    width: 100%;
}

.letter-preview {
    background-color: #fff;
}