.kids {
    /* padding: 0 2rem; */
}

.stepper-container {
    padding: 2rem 15%;
}

@media (max-width: 768px) {
    .stepper-container {
        padding: 2rem 0;
    }
}

.no-wishlist-message {
    font-size: 2rem;
    margin-top: 4rem;
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
