.form {
  padding: 2rem;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1rem;
  color: #fff;
  font-family: Mali;
  font-size: 1.2rem;
}

/* Responsive for tablets and mobile screens */

.first {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.write-letter-page {
  color: black;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.letter {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: calc((100vw - 26rem) / 1.78);
}

.second {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  margin: 2rem 0;
}

.second .MuiFormGroup-root {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.second .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
  color: #fff;
}

[contenteditable] {
  display: block;
  font-size: 16px;
  line-height: 2em;
  min-height: 6em;
  background-image: linear-gradient(transparent 1.95em, #00000023 0.5em);
  background-size: 100% 2em;
  outline: none;
  color: black;
}

.third .text {
  margin-bottom: 2rem;
}

.chips-input.MuiFormControl-root.MuiTextField-root.MuiChipsInput-TextField {
  width: 100% !important;
}

.chips-input.MuiFormControl-root.MuiTextField-root.MuiChipsInput-TextField .MuiInputBase-root.MuiOutlinedInput-root {
  border: none !important;
  border-bottom: 1px solid #00000023;
  padding-left: 0;
  padding-bottom: 0;
  border-radius: 0 !important;
  width: 100%;
}

span.MuiChip-label.MuiChip-labelMedium {
  color: black;
  font-family: Mali;
  font-size: 16px;
}

@media (max-width: 1024px) {
  .form {
    width: 90%;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .form {
    width: 100%;
    /* padding: 1.5rem; */
    font-size: 1rem;
  }

  .write-letter-page .second {
    margin: 1rem 0;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .form {
    width: 100%;
    padding: 1rem;
    font-size: 0.85rem;
  }

  .write-letter-page {
    padding: 1rem;
  }

  .write-letter-page .first {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .second {
    gap: 0.5rem;
  }

  .chips-input.MuiFormControl-root {
    width: 100% !important;
  }
}
