/* General container styles */
.santa-talk-container-outer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-top: 30px; */
  /* margin-left: 20px; */
  /* left: 20px; */
}

.santa-talk-container-outer-2 {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 50px;
}

.santa-talk-container-main {
  margin-bottom: 20px;
}

.santa-talk-product-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.santa-talk-product-title span {
  color: #ac0101;
}

.santa-talk-container-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
}

/* Video and button container */
.video-and-button-container {
  position: fixed;
  bottom: 60px;
  right: 0;
  z-index: 1000;
  text-align: center;
  transition: all 0.3s ease;
}

.video-and-button-container.not-fetched {
  position: absolute;
  bottom: auto;
  right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container {
  transition: all 0.3s ease;
}

.video-container.large {
  width: 80vw;
  max-width: 400px;
  height: auto;
}

.video-container.small {
  width: 400px;
  height: auto;
}

/* Conversion button styles */
.conversion-buttons {
  margin-top: 10px;
}

.conversion-buttons.overlay {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}

.selection-done {
  position: fixed;
  top: 90px; /* Adjust the distance from the top of the page */
  right: 2vw; /* Adjust the distance from the right edge of the page */
  z-index: 1000;
}

/* Button style */
button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff6347;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

/* Responsive design */
@media (max-width: 768px) {
  .video-container.large {
    width: 90vw;
  }

  .video-container.small {
    width: 400px;
  }

  .video-and-button-container {
    bottom: 50px;
  }

  .santa-talk-container-main {
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .video-container.large {
    width: 90vw;
  }

  .video-container.small {
    width: 150px;
  }

  .santa-talk-container-products {
    flex-direction: column;
    align-items: center;
  }

  .conversion-buttons.overlay {
    left: 20%;
    transform: translateX(0%);
  }

  .conversion-buttons {
    margin-top: 5px;
  }
}
