.header-nav {
    padding: 2rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: brown; */
}

@media (max-width: 768px) {
    .header-nav {
        padding: 2rem 1rem;
    }
}