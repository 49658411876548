@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&family=Mali:wght@600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&display=swap');

.kids-heading {
  color: #d70f0f;
  font-family: Figtree;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 1rem;
}

.MuiStepLabel-iconContainer svg {
  color: transparent;
  border: 1px solid #292929;
  border-radius: 50%;
}

.MuiStepLabel-iconContainer text {
  fill: #292929;
}

.MuiStepLabel-iconContainer.Mui-completed svg {
  color: #d70f0f;
  border: none;
}

.MuiStepLabel-iconContainer.Mui-active svg {
  border: 1px solid #d70f0f;
  border-radius: 50%;
  color: transparent;
}

.MuiStepLabel-iconContainer.Mui-active text {
  fill: #d70f0f;
}

.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-active .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
  border-color: #d70f0f;
}

.MuiStepLabel-label {
  font-size: 1rem !important;
}

.MuiGrid-root.MuiGrid-container .MuiFormControl-root {
  margin: 0;
  margin-bottom: 1.5rem;
}

.MuiBox-root {
  outline: none;
}

.write-letter {
  padding: 2rem 0;
}

/* label.MuiInputLabel-shrink {
  display: none;
} */

.page-heading {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  color: #d70f0f;
  font-family: Figtree;
  font-weight: 700;
}

.page-heading-view {
  margin-bottom: 2rem;
  font-size: 2.6rem;
  color: #d70f0f;
  font-family: Figtree;
  font-weight: 700;
}

.page-subtext {
  margin-bottom: 2rem;
  color: #292929;
  font-family: Figtree;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.page-subheading {
  color: #292929;
  font-family: Figtree;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1.5rem;
}

.page-subheading-view {
  color: #292929;
  font-family: Figtree;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1.5rem;
}

.page-section-separator {
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin: 2rem 0;
}

/* .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: #292929;
  font-family: Figtree;
  font-weight: 700;
  font-size: 14px;
} */

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  text-transform: none;
}

.tabs .MuiTabs-indicator {
  background-color: #d70f0f;
}

.tabs .MuiTabPanel-root {
  padding: 0 0 20px 0;
}

.slick-next:before,
.slick-prev:before {
  color: #000;
}

.finalise .finalise-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.finalise .search input {
  height: 37px;
  border-radius: 32px;
  border: 1px solid #d9d9d9;
  color: #6f6f6f;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  margin-right: 1rem;
  width: 35%;
  margin-bottom: 2rem;
}

@media (max-width:480px) {
  .finalise .search input {
    width: 60%;
  }
}

.sharelist .sharelist-products {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.sharelist-separator {
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
  margin: 2.5rem 0;
}

.share-form textarea,
.share-form input {
  padding: 12px;
  width: 50%;
  margin-bottom: 1rem;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  color: #878787;
  font-family: Figtree;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.share-form input {
  height: 1.5rem;
}

.letter-preview .MuiInputBase-input.MuiInput-input.Mui-readOnly {
  font-family: Figtree;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.fetch-data {
  font-family: Figtree;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items:center;
  /* height: 60vh; */
  color: rgb(40, 71, 28);
  padding: 5vw;
}

.wishlist-note {
  font-family: Figtree;
  margin-top: 0.5rem;
  font-weight: 900;  
}

.share {
  font-family: Figtree;
  font-weight: 900;
  margin-bottom: 2rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
