.template-holder-item {
    border: 1px solid #C9C9C9;
    display: flex;
    gap: 0.75rem;
    padding: 0.75rem;
    cursor: pointer;
}

.template-holder-item img {
    width: 15vw;
    box-sizing: border-box;
    height: auto;
}