.template-item {
    border: 1px solid #C9C9C9;
    gap: 0.75rem;
    padding: 0.75rem;
    cursor: pointer;
    width: 250px;
}

.template-item img {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}