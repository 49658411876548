.view-product-details {
  position: relative;
}

.view-product-details .description {
  font-size: 1rem;
  text-align: center;
  padding: 25px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

@media (min-width: 600px) {
  .content-container {
    flex-direction: row;
    justify-content: center;
  }
}

.product-display img {
  width: 100%;
  max-width: 150px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.product-features {
  text-align: center;
}

.product-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.product-stars {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.rating {
  font-size: 1.3rem;
}

.product-price {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.product-platform img {
  width: 80px;
  margin: 0.5rem 0;
}

.unselected-product-view-details,
.selected-product-view-details {
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  border: 2px solid #005424;
  box-shadow: 0px 3px 14px 0px rgba(17, 0, 0, 0.2);
  cursor: pointer;
  color: #005424;
  font-family: Figtree;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 8rem;
  border: 1px solid black;
}

.selected-product-view-details {
  background: #005424;
  color: #fff;
}

.selected-product-view-details img {
  width: 30px;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
