
.overlay-video {
    position: fixed;
    top: 100px;
    right: 20px;
    z-index: 100000;
  }
  
  .overlay-video video {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    z-index: 100000;
  }