.product {
    display: flex;
    color: #292929;
    font-size: 1rem;
    font-weight: 500;
    height: 2.5rem;
    align-items: baseline;
}

.product .label {
    margin-left: 1rem;
}

.product .label-input {
    margin-left: 1rem;
}

.product .label-input input {
    color: #292929;
    font-size: 1rem;
    font-weight: 500;
}

.product .icon {
    margin-left: 1rem;
    cursor: pointer;
}