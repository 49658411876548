.template-container {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.template-container p {
    font-family: Figtree;
    color: #000;
    font-weight: 700;
    margin: 1rem 0;
}

.template-holder {
    display: flex;
    gap: 0.75rem;
    flex-direction: row;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

.template-buttons {
    display: flex;
    justify-content: space-between;
}

.template-container p.kids-heading {
    color: #D70F0F;
    font-family: Figtree;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 2rem;
    margin-bottom: 1rem;
}