.sidebar {
    background-color: #AC0101;
    height: 100vh;
    color: #fff;
    font-size: 14px;
    position: sticky;
    top: 0;
}

.sidebar svg {
    /* width: 24px; */
}

.sidebar .navbar {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 12px 2rem;
    cursor: pointer;
}

.sidebar a {
    text-decoration: none;
    color: #fff;
}

.sidebar a.active .navbar, .sidebar a:hover .navbar {
    background-color: #005424;
}

.sidebar .bottom {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #DF0000;
    width: 100%;
}